import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/cloud/thingos-homepage/packages/meshlighting/src/components/BlogLayout.tsx";
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { BlogImageWrapper } from '../../components/BlogImageWrapper';
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		hero: file(relativePath: { eq: "blog-posts/hub-network3.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
		meshNetworkIllustration: file(relativePath: { eq: "blog-posts/meshapp-network.png" }) {
			childImageSharp {
				gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
	}
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Das Verbinden zweier Geräte via Bluetooth war nicht immer einfach. Bei Einführung des Bluetooth®-Standards, 1999, war es beispielsweise nicht möglich, Geräte unterschiedlicher Hersteller miteinander zu verbinden. Erst zahlreiche Entwicklungsschritte, verbesserte die Interoperabilität, die Funktionalität, die Datenrate, die Reichweite und machten ihn zu dem weltweit anerkannten Kommunikationsstandard, wie wir ihn heute kennen.`}</p>
    <h4>{`Bluetooth® Mesh Zusammengefasst`}</h4>
    <GatsbyImage image={getImage(props.data.meshNetworkIllustration)} alt="Mesh Network" imageStyle={{
      padding: '20px'
    }} mdxType="GatsbyImage" />
    <p>{`Der 2017 von der Bluetooth® Special Interest Group (Bluetooth® SIG) veröffentlichte Bluetooth® Mesh Standard geht noch einen Schritt weiter und zielt auf die Anforderungen des wachsenden IoT-Markts ab. Bluetooth® Kommunikation, die früher ausschließlich von einem auf ein anderes Gerät möglich war, kann seitdem auch über das Publishing/Subscribe-Modell Nachrichten an eine Gruppe von Geräten gleichzeitig senden. Das bedeutet vereinfacht gesagt:
Kompatible Bluetooth® Geräte hängen sich zu einem sicheren Netzwerk zusammen und schicken an alle in Reichweite gelegenen Mesh Geräte verschlüsselte Nachrichten heraus, anstatt gezielt mit einem Gerät zu kommunizieren. Geräte, die diese Nachricht erhalten, also in Reichweite des “Publishers” sind, prüfen, ob sie diese Art von Nachricht abonniert haben, also „Subscriber“ sind. Trifft dies zu, wird der darin enthaltene Befehl ausgeführt. In jedem Fall wird die Nachricht an die umliegenden Nachbarn weitergeleitet, welche sich wiederum genau so verhalten, so dass nach kürzester Zeit alle Netzwerk-Teilnehmer mindestens einmal die Nachricht erhalten haben. Um zu verhindern das sich Geräte die gleiche Nachricht immer wieder hin und her schicken, merkt sich jedes Gerät ob es die Nachricht bereits erhalten hat. Im Fachjargon wird dies “relay protection” genannt, welches gleichzeitig auch zu einer erhöhten Netzsicherheit führt.`}</p>
    <h4>{`Welche Vorteile bringt Bluetooth® Mesh?`}</h4>
    <p>{`Für Bluetooth® gesteuerte Haus- und Gebäudeautomationen hat sich diese dezentrale Art der Kommunikation als zukunftsweisender Game Changer herausgestellt.
Die Anzahl teilnehmender Geräte solcher Netzwerke sind dabei kaum Grenzen gesetzt - im Gegenteil, je mehr Geräte mitmachen, umso robuster und zuverlässiger funktioniert es. Fällt ein Knotenpunkt aus, weil dieser defekt ist, keinen Strom hat oder außer Reichweite ist, findet die Nachricht ihr Ziel über einen anderen Weg. Durch dieses “Flooding”-Prinzip wird eine hohe Robustheit und Ausfallsicherheit des Gesamtnetzwerks erreicht. Gleichzeitig wird die maximale Reichweite um ein Vielfaches erhöht, da durch das beschriebene “Hopping” der Nachrichten, die Sendeleistung einzelner Geräte in den Hintergrund rückt. In der Praxis können so Distanzen über mehrere Etagen und Gebäude überwunden werden.`}</p>
    <h4>{`Vorteile gegenüber anderer Funk-Standards?`}</h4>
    <p>{`Wie auch schon bei der Erfolgsgeschichte des Bluetooth® Standards vor 20 Jahren, ist die vollumfängliche Kompatibilität mit anderen Herstellern der eigentliche Paradigmenwechsel, da Insellösungen, auf die Kunden beim Kauf neuer IoT-Geräte noch Rücksicht nehmen müssen, das Wachstum der IoT-Branche stark ausbremst. In der von der Bluetooth SIG herausgegeben Spezifikation wird nicht nur definiert, wie Nachrichten an ihr Ziel kommen, sondern vor allem auch wie deren Inhalt aufgebaut sein muss - in Form von so genannten “Modellen”. So lässt sich für Entwickler nachlesen, wie beispielsweise eine Lampe gedimmt werden soll. Da alle Teilnehmer die gleiche Spezifikation anwenden gelingt so eine einheitliche Kommunikation verschiedener Hersteller. Mögliche Interpretationsspielräume, und somit mögliche Inkompatibilitäten werden mit der Zeit durch die Praxis ausgeräumt.`}</p>
    <h4>{`Blick in die Zukunft`}</h4>
    <p>{`177 Millionen Bluetooth Mesh fähige Geräte wurden im Jahr 2020 bereits verkauft (`}<a style={{
        "color": "rgba(255,255,255,0.5)"
      }} target="_blank" href="https://www.bluetooth.com/bluetooth-resources/2021-bmu/">{`Quelle`}</a>{`). Das ist im Vergleich zum Vorjahr ein Zuwachs von 42 Prozent, welcher für das Jahr 2021 sogar auf 53 Prozent prognostiziert wird. Im Jahr 2025 soll die Zahl auf stolze 770 Millionen Geräte steigen. Unternehmen wie Amazon und Osram haben diesen Trend ebenso erkannt und setzen mit ihren Sprachassistenten und Beleuchtungs Produkten, bereits genau auf diesen Standard. Wir haben keine Zweifel, dass es Bluetooth auch hier gelingen wird den Standard für vernetzte Beleuchtung zu setzten, potentiell sogar für kabellose IoT-Sensorik im Gebäude-Segment.`}</p>
    <h4>{`Unsere Expertise`}</h4>
    <p>{`Wir - die ThingOS GmbH & Co. KG - bieten mit unseren Bluetooth Mesh-Produkten `}<a style={{
        "color": "#f99b43"
      }} target="_blank" href="https://mesh-lighting.com/#app-details">{`Meshthings App`}</a>{`, `}<a style={{
        "color": "#f99b43"
      }} target="_blank" href="https://mesh-lighting.com/#firmware-details">{`Meshlighting Firmware`}</a>{` und `}<a style={{
        "color": "#f99b43"
      }} target="_blank" href="https://mesh-lighting.com/#hub-details">{`Mesh Hub`}</a>{` die wichtigsten Software Komponenten für den schnellen und erfolgreichen Einstieg in den Markt für Bluetooth Mesh kompatible Produkte. Gerne unterstützen wir Sie auch in der frühen Phase und beraten Sie kompetent zu allen Bluetooth Mesh und IoT-Themen.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      